.demo-swiper .rv-swiper {
  width: 360px !important;
  height: 150px !important;
  padding: 0px !important;
}
.demo-swiper .rv-swiper img {
  width: 360px;
  height: 150px;
}
.demo-swiper .rv-swiper img {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  background-color: #fff;
  pointer-events: none;
}
.bottom_last {
  padding-bottom: 80px !important;
}
.rv-search__content {
  border-radius: 8px !important;
}
.rv-coupon-list__list {
  padding-bottom: 150px !important;
}
.rv-coupon-list__list--with-bottom {
  padding-bottom: 150px !important;
}
