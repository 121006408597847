.demo-swipe-cell {
  user-select: none;
}
.demo-product-card {
  padding: 10px 16px;
  background-color: #fff;
}
.demo-product-card__img {
  width: 88px;
  height: 88px;
  margin-right: 10px;
}
.demo-product-card__content {
  flex: 1;
}
.bottom_last {
  padding-bottom: 80px !important;
}
