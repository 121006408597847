.rv-sidebar__wrapper {
  min-height: 100vh !important;
}
.rv-sidebar__wrapper .rv-sidebar {
  background-color: #f7f8fa !important;
}
.bottom_last {
  padding-bottom: 80px !important;
}
.rv-coupon-list__list {
  padding-bottom: 150px !important;
}
.rv-coupon-list__list--with-bottom {
  padding-bottom: 150px !important;
}
